<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 300px; overflow-y: hidden"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      @onChange="onChange"
      @onBlur="onBlur"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { postdata } from "@/api";
// import { IEditorConfig } from "@wangeditor/editor";

export default Vue.extend({
  props: ["editorData", "num", "laffilepath", "lafurl"],
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      html: '',
      toolbarConfig: {},
      editorConfig: { 
        placeholder: "请输入内容...", 
        MENU_CONF: {},
        autoFocus: false,
        scroll: true
      },
      mode: "default",
    };
  },
  watch: {
    editorData: {
      handler(newVal) {
        if (newVal && this.editor) {
          this.$nextTick(() => {
            try {
              this.html = newVal === "<p>hi</p>" ? '' : newVal;
            } catch (error) {
              console.warn('编辑器内容更新失败:', error);
            }
          });
        }
      },
      immediate: true
    }
  },

  methods: {
    onCreated(editor) {
      try {
        this.editor = Object.seal(editor);
        if (this.editorData && this.editorData !== "<p>hi</p>") {
          this.html = this.editorData;
        }
      } catch (error) {
        console.warn('编辑器创建错误:', error);
      }
    },

    onChange(editor) {
      try {
        this.$emit("sendEditor", this.html, this.num);
      } catch (error) {
        console.warn('onChange 错误:', error);
      }
    },
    onBlur(editor) {
      this.$emit("sendEditor", this.html, this.num);
    },

    async dealdata(url, data) {
      return await postdata(url, data).then((res) => {
        //console.log(res.data);
        return res;
      });
    },
    formattime() {
      const today = new Date();

      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0"); // 月份从0开始，所以加1
      const day = String(today.getDate()).padStart(2, "0");

      const formattedDate = `${year}${month}${day}`;
      console.log(formattedDate); // 输出格式为：YYYY-MM-DD
      return formattedDate;
    },
    uploadfile() {
      let that = this;
      that.editorConfig.MENU_CONF["uploadImage"] = {
        // 自定义上传
        async customUpload(file, insertFn) {
          console.log(file, insertFn);

          let formData = new FormData();
          formData.append("file", file);
          formData.append("flag", "file");

          let path = that.laffilepath + that.formattime();
          console.log(path);
          formData.append("filepath", path);
          //formData.append("filepath", that.laffilepath);

          let res = await that.dealdata(that.lafurl, formData);

          console.log(res.data.data);

          const { name, url, code } = res.data.data[0];

          // <p><img src="15.jpg" alt="https://oss.laf.run/nnjacn-csco/jijiu20241014/15.jpg" data-href="success" style=""/>模拟 Ajax 异步设置内容 HTML</p>
          console.log(name, url, code);
          insertFn(url, code, name);
        },
      };
    },
  },

  created() {
    this.uploadfile();
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = "<p>模拟 Ajax 异步设置内容 HTML</p>";
    // }, 1500);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
});
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
