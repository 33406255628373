<template>
  <div class="header-container">
    <div class="l-content">
      <el-button
        @click="handleMenu"
        icon="el-icon-menu"
        size="mini"
      ></el-button>
      <!-- <span>首页</span> -->
      <el-breadcrumb separator-class="el-icon-arrow-right">
         <el-breadcrumb-item
          class="myColor"
          v-for="item in tags"
          :key="item.path"
          @click.native="navigateTo(item.path)"
          >{{ item.label }}</el-breadcrumb-item
        >
        <!-- <el-breadcrumb-item
          class="myColor"
          v-for="item in tags"
          :key="item.path"
          :to="{ path: item.path }"
          >{{ item.label }}</el-breadcrumb-item
        > -->
      </el-breadcrumb>
    </div>
    <div class="r-content">
      <el-dropdown>
        <span class="el-dropdown-link">
          <i class="el-icon-share"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>个人中心</el-dropdown-item>
          <el-dropdown-item>退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  methods: {
    handleMenu() {
      this.$store.commit("collapseMenu");
    },

    navigateTo(route) {
      this.$router.push(route).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          // 不是重复导航错误，抛出以便捕获其他潜在错误
          throw err;
        }
        // 重复导航，可以选择记录或者完全忽略
      });
    },
  },
  computed: {
    ...mapState({
      tags: (state) => state.tab.TabList,
    }),
  },
  mounted() {
    console.log(this.tags, "tags");
  },
};
</script>

<style lang="less" scoped>
.header-container {
  background-color: #4e4c4c;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  .l-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgb(94, 95, 97);

    .myColor /deep/ .el-breadcrumb__inner {
      color: #fff;
      border-radius: 5px;
      border: 2px solid #86856d;
      padding: 5px;
      &:last-child {
        color: #4e4c4c;
      }
    }

    .myColor:last-child {
      color: #4e4c4c;
    }
  }

  span {
    color: aliceblue;
    padding: 0 20px;
  }
}
</style>
