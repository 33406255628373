import { render, staticRenderFns } from "./DrugManage.vue?vue&type=template&id=75674ab3&scoped=true"
import script from "./DrugManage.vue?vue&type=script&setup=true&lang=js"
export * from "./DrugManage.vue?vue&type=script&setup=true&lang=js"
import style0 from "./DrugManage.vue?vue&type=style&index=0&id=75674ab3&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75674ab3",
  null
  
)

export default component.exports