<template>
  <div class="treebox">
    <el-row :gutter="10">
      <el-col :span="4">
        <div class="menu">
          <div class="title">疾病分类</div>
          <Tree @node-click="handleNodeClick"></Tree>
        </div>
      </el-col>
      <el-col :span="20">
        <div class="content">
          <div class="subtitle">{{ title }}流程</div>
          <Form :node-data="selectedNodeData" :formData="formData"></Form>

          <!-- <el-form>
            <el-form-item label="疾病名称" prop="name">
              <el-input v-model="formData.name"></el-input>
            </el-form-item>
          </el-form> -->
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Tree from "@/views/JijiuManage/Jijiutree.vue";
import Form from "@/views/JijiuManage/JijiuForm.vue";

import { mapState } from "vuex";

export default {
  components: {
    Tree,
    Form,
  },

  data() {
    return {
      selectedNodeData: null,
      formData: {
        _id: "",
        name: "",
        uploadedFiles: [],
        desc:""
      },
    };
  },
  computed: {
    ...mapState("jijiu", ["title", "list"]),
  },

  mounted() {
    if (this.title == "编辑") {
      console.log("传过来的行数据", this.list);

      // this.formData.uploadedFiles = this.list.uploadedFiles;
      // this.formData.name = this.list.name;
      // this.formData._id = this.list._id;

      // console.log("传过来的行数据", this.list);

      if (this.list) {
        this.formData.uploadedFiles = this.list.uploadedFiles || [];
        this.formData.name = this.list.name || "";
        this.formData._id = this.list._id || "";
        this.formData.desc = this.list.desc || "";
      } else {
        console.warn("this.list 数据未定义或未正确传入");
      }

      console.log("###################", this.formData);
    }
  },

  methods: {
    handleNodeClick(tree) {
      console.log("handleNodeClick----------父组件接受数据", tree);
      this.selectedNodeData = tree;
    },
  },
};
</script>

<style lang="less" scoped>
.treebox {
  border-radius: 4px;
}
.menu {
  height: 800px;

  .title {
    height: 40px;
    line-height: 40px;
    color: white;
    background-color: skyblue;
    font-size: 16px;
    padding-left: 10px;
  }
}

.content {
  height: 800px;
  .subtitle {
    height: 40px;
    line-height: 40px;
    color: white;
    background-color: skyblue;
    font-size: 16px;
    padding-left: 10px;
  }
}
</style>
