<template>
  <el-menu
    default-active="1-4-1"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    :collapse="isCollapse"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b"
  >
    <h3>{{ isCollapse ? "后台" : "通用后台管理系统" }}</h3>
    <el-menu-item
      v-for="item in noChildren"
      :key="item.name"
      :index="item.name"
      @click="clickmenu(item)"
    >
      <i :class="`el-icon-${item.icon}`"></i>
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>

    <el-submenu
      v-for="item in hasChildren"
      :key="item.label"
      :index="item.label"
    >
      <template slot="title">
        <i :class="`el-icon-${item.icon}`"></i>
        <span slot="title">{{ item.label }}</span>
      </template>

      <el-menu-item-group v-for="subitem in item.children" :key="subitem.path">
        <el-menu-item @click="clickmenu(subitem)" :index="subitem.path">
          <template slot="title">
            <i :class="`el-icon-${subitem.icon}`"></i>
            <span slot="title">{{ subitem.label }}</span>
          </template>
        </el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu {
  height: 100%;
  border-right: none;
  h3 {
    color: rgb(231, 221, 221);
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    font-weight: 400px;
  }
}
</style>

<script>
export default {
  data() {
    return {
      menuData: [
        {
          path: "/",
          name: "home",
          label: "首页",
          icon: "s-home",
          url: "Home/Home",
        },
        {
          path: "/mall",
          name: "mall",
          label: "临床指南",
          icon: "video-play",
          url: "MallManage/MallManage",
        },
        {
          path: "/clinic",
          name: "clinic",
          label: "临床试验",
          icon: "share",
          url: "Clinic/Clinic",
        },

        {
          path: "/wenxin",
          name: "wenxin",
          label: "文心一言",
          icon: "user",
          url: "WenxinManage/WenxinManage",
        },
        {
          path: "/user",
          name: "user",
          label: "用户列表",
          icon: "user",
          url: "UserManage/UserManage",
        },
        {
          path: "/jijiu",
          name: "jijiu",
          label: "急救流程",
          icon: "setting",
          url: "Jijiu/Jijiu",
        },
        {
          path: "/mianyi",
          name: "mianyi",
          label: "免疫资料",
          icon: "video-play",
          url: "Miami/Mianyi",
        },
        {
          label: "管理工具",
          icon: "location",
          children: [
            {
              path: "/export",
              name: "export",
              label: "导出文件",
              icon: "setting",
              url: "Other/Export",
            },
            {
              path: "/drugmanage",
              name: "drugmanage",
              label: "药品管理",
              icon: "share",
              url: "Other/drugmanage",
            },
            {
              path: "/jijiumanage",
              name: "jijiumanage",
              label: "急救管理",
              icon: "setting",
              url: "Other/jijiumanage",
            },
            {
              path: "/mianyimanage",
              name: "mianyimanage",
              label: "免疫管理",
              icon: "video-play",
              url: "MiamiManage/MianyiManage",
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    clickmenu(item) {
      //console.log(item);
      if (
        this.$route.path !== item.path &&
        !(this.$route.path === "/home" && item.path === "/")
        // !(this.$route.path === "/clinic" && item.path === "/")
      ) {
        this.$router.push(item.path);
      }
      this.$store.commit("selectMenu", item);
    },
  },

  computed: {
    noChildren() {
      return this.menuData.filter((item) => !item.children);
    },
    hasChildren() {
      return this.menuData.filter((item) => item.children);
    },
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
  },
};
</script>
