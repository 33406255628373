export default {
    namespaced: true,
    state: {
        title: "添加",
        list: {}
    },

    mutations: {

        changeTitle(state, payload) {
            state.title = payload
        },
        changeList(state, payload) {
            state.list = payload
        }

    },
    actions: {

    }


}