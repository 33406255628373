<template>
  <div>
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="用户管理" name="first">用户管理</el-tab-pane>
      <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
    </el-tabs> -->

    <!-- <h3>免疫管理</h3> -->
    <!-- <router-link to="mianyi/mianyimanage" class="tab-link">免疫管理</router-link>
    <router-link to="/mianyi" class="tab-link">免疫资料</router-link>
    <router-view></router-view> -->

    <div class="searchbox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <el-button type="primary" @click="addnew">+新增数据</el-button>
        </el-form-item>
        <el-form-item label="疾病名">
          <el-input
            v-model="formInline.keyword"
            @input="searchinput"
            placeholder="请输入关键词"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- <el-button type="primary" @click="addnew">+新增数据</el-button> -->

    <div style="margin-top: 8px">
      <el-table :data="userslist" style="width: 100%" border stripe>
        <!-- <el-table-column prop="name" label="疾病名"></el-table-column> -->
        <!-- 外层表格动态列 -->
        <el-table-column
          v-for="col in columns"
          :key="col"
          :prop="col"
          :label="getLabel(col)"
          align="center"
          min-width="50"
          max-width="100"
          width="100"
        >
        </el-table-column>

        <!-- 子数组动态表格列 -->
        <el-table-column label="详情" align="center" max-width="450">
          <template #default="scope">
            <!-- 内层表格 -->
            <el-table :data="scope.row.items" style="width: 100%">
              <el-table-column
                v-for="nestedCol in nestedColumns"
                :key="nestedCol"
                :prop="nestedCol"
                :label="getNestedLabel(nestedCol)"
                align="center"
              >
                <template #default="{ row }">
                  <div>
                    <quill-editor
                      :value="row[nestedCol]"
                      @input="
                        (value) => handleEditorChange(value, row, nestedCol)
                      "
                      :options="editorOptions"
                    ></quill-editor>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>

        <el-table-column label="操作" max-width="150" width="150">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="block">
      <el-pagination
        :total="total"
        :page-size="pagesize"
        @current-change="currentChange"
        :current-page="currentPage"
      >
      </el-pagination>
    </div>

    <!-- <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <span slot="title">
        <span class="header-main-title">{{
          isnew ? "新增数据" : "编辑数据"
        }}</span>
      </span>
      <el-form
        :inline="true"
        :rules="rules"
        ref="form"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="急救流程名" prop="name">
          <el-input
            placeholder="请输入急救流程名"
            v-model="form.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="附件" prop="fileurl">
          <el-input placeholder="" v-model="form.fileurl"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { postdata } from "@/api";
import { mapMutations } from "vuex";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      userslist: [],
      columns: ["name"],
      nestedColumns: ["subtitlevalue", "editorvalue"], // 子数组字段

      isnew: true,
      dialogVisible: false,

      total: 50,
      pagesize: 10,
      currentPage: 1,
      form: {
        name: "",
        img: "",
        fileurl: "",
      },

      formInline: {
        keyword: "",
      },

      editorOptions: {
        placeholder: "在这里输入内容...",
        theme: "snow", // 主题样式，可以选择 'snow'、'bubble' 等,
        modules: {
          toolbar: false,

          // [
          //   ['bold', 'italic', 'underline', 'strike'],
          //   ['blockquote', 'code-block'],
          //   [{ 'header': 1 }, { 'header': 2 }],
          //   [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          //   [{ 'indent': '-1'}, { 'indent': '+1' }],
          //   ['clean']
          // ]
        },
      },
    };
  },

  mounted() {
    this.getdata(this.currentPage);
    //this.handleTextChange()
  },

  methods: {
    //导入仓库
    ...mapMutations("mianyi", ["changeTitle", "changeList"]),

    // handleClose() {
    //   console.log(this.$refs.form);
    //   this.dialogVisible = false;
    // },
    // submit() {
    //   this.$refs.form.validate((valid) => {
    //     console.log(valid, "valid");
    //     if (valid) {
    //       if (this.isnew) {
    //         this.managedata({ ...this.form, flag: "new" });
    //         this.$message.error("添加成功");
    //       } else {
    //         this.managedata({ ...this.form, flag: "edit" });
    //       }
    //       //this.$refs.form.resetFields();
    //       this.dialogVisible = false;
    //     }
    //   });
    // },
    // cancel() {
    //   this.handleClose();
    // },

    searchinput(e) {
      console.log(e);
      if (e) {
        this.onSearch();
      } else {
        this.getdata();
      }
    },

    async onSearch() {
      console.log("keyword---------------", this.formInline.keyword);
      await postdata("/getmianyi", {
        flag: "search",
        keyword: this.formInline.keyword,
      }).then((data) => {
        console.log("数据", data);

        this.userslist = data.data;
        console.log("search数据", this.userslist);
      });
    },

    currentChange(page) {
      console.log(page);
      this.getdata(page);
    },

    getLabel(col) {
      const labels = {
        name: "疾病名",
      };
      return labels[col] || col;
    },

    // 子数组字段的标签
    getNestedLabel(nestedCol) {
      const labels = {
        subtitlevalue: "副标题",
        editorvalue: "描述",
      };
      return labels[nestedCol] || nestedCol;
    },

    async getdata(currentPage) {
      await postdata("/getmianyi", { flag: "pagesize", currentPage }).then(
        (res) => {
          console.log("新数据", res.data);
          this.userslist = res.data.data;
          this.total = res.data.total;
        }
      );
    },

    addnew() {
      //this.$refs.form.resetFields();
      this.isnew = true;
      this.form = {};
      //this.dialogVisible = true;
      this.changeTitle("添加");
      this.changeList({});
      this.$router.push("/mianyimanage");
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.isnew = false;
      //this.dialogVisible = true;
      this.form = row;

      this.changeTitle("编辑");
      this.changeList(row);

      console.log("编辑跳转前", row);

      this.$router.push("/mianyimanage");
    },

    async managedata(data) {
      console.log("数据", data);
      await postdata("/getmianyi", data).then(async (res) => {
        console.log("getmianyi", res.data.data);
        await this.getdata(this.currentPage); // 调用获取用户信息的函数，更新列表
      });
    },
    handleDelete(index, row) {
      console.log("删除行的数据", { ...row, flag: "delete" });
      this.managedata({ ...row, flag: "delete" });
      //this.$message.error("删除成功");
    },

    handleEditorChange(value, row, field) {
      console.log("更新对应字段的值", value, row, field);
      // 更新对应字段的值
      row[field] = value;

      // 如果需要立即保存到后端，可以在这里调用保存方法
      //this.saveEditorContent(row);
    },

    // 可选：保存到后端的方法
    // async saveEditorContent(row) {
    //   try {
    //     await postdata("/getmianyi", {
    //       flag: "data",
    //       id: row.id,
    //       data: row
    //     });
    //   } catch (error) {
    //     console.error('保存失败:', error);
    //     this.$message.error('保存失败');
    //   }
    // }
  },
};
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.searchbox {
  background-color: white;
  height: 66px;
}

.demo-form-inline {
  padding: 10px;
  display: flex;
  align-items: center !important ;
}

/* 禁用鼠标事件 */
.ql-editor {
  pointer-events: none;
}

/* 移除编辑器边框 */
.ql-container {
  border: none !important;
}

/* 隐藏工具栏 */
.ql-toolbar {
  display: none;
}

.tab-link {
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-decoration: none;
}
</style>
