const title = [{

    value: "免疫治疗相关皮肤毒性管理",
    text: "免疫治疗相关皮肤毒性管理"
}, {
    value: "反应性皮肤毛细血管增生症管理",
    text: "反应性皮肤毛细血管增生症管理"
},
{
    value: "心脏毒性管理",
    text: "心脏毒性管理"
}, {
    value: "肾脏毒性管理",
    text: "肾脏毒性管理"
},
{
    value: "肝脏毒性管理",
    text: "肝脏毒性管理"
},
]


const subtitle = [{
        value: "概述",
        text: "概述"
    },
    {
        value: "诊断、分级与治疗",
        text: "诊断、分级与治疗"
    }, {
        value: "治疗与预防",
        text: "治疗与预防"
    }, {
        value: "典型病例",
        text: "典型病例"
    }

]

export {
    title,
    subtitle
}



//  {
//      title: "反应性皮肤毛细血管增生症管理",
//      subtitle: {
//          value: "",
//          options: [{
//                  value: "反应性皮肤毛细血管增生症概述",
//                  text: "反应性皮肤毛细血管增生症概述"
//              },
//              {
//                  value: "反应性皮肤毛细血管增生症管理的诊断、分级",
//                  text: "反应性皮肤毛细血管增生症管理的诊断、分级"
//              }, {
//                  value: "反应性皮肤毛细血管增生症的治疗与预防",
//                  text: "反应性皮肤毛细血管增生症的治疗与预防"
//              }, {
//                  value: "反应性皮肤毛细血管增生症管理典型病例",
//                  text: "反应性皮肤毛细血管增生症管理典型病例"
//              },
//          ],
//      },

//  },