<template>
  <div class="formbox">
    <el-form
      ref="form"
      :model="formData"
      label-width="80px"
      label-position="left"
    >
      <el-form-item label="疾病名称" prop="name">
        <!-- <el-input v-model="formData.name"> </el-input> -->
        <el-select v-model="formData.name" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="时间">
        <el-date-picker
          v-model="formData.time"
          align="right"
          type="date"
          placeholder="选择日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>

      <div v-for="(item, index) in formData.items" :key="index">
        <el-form-item label="标题">
          <el-select
            v-model="item.subtitlevalue"
            placeholder="请选择"
            style="margin-right: 16px"
          >
            <el-option
              v-for="item in item.subtitle.options"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="详情">
          <wang-editor
            @sendEditor="sendEditor"
            :editorData="item.editorvalue || item.editor.value"
            :num="index"
            :lafurl="lafurl"
            :laffilepath="laffilepath"
          ></wang-editor>
        </el-form-item>

        <el-form-item label="编辑">
          <el-button @click="removeItem(index)">删除</el-button>
        </el-form-item>

        <el-divider></el-divider>
      </div>
      <!-- <div v-for="(item, index) in formData.items" :key="index">
        <el-form-item label="标题">
          <el-select
            v-model="item.subtitle.value"
            placeholder="请选择"
            style="margin-right: 16px"
          >
            <el-option
              v-for="item in item.subtitle.options"
              :key="item.value"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="详情">
          <wang-editor
            @sendEditor="sendEditor"
            :editorData="item.editor.value"
            :num="index"
            :lafurl="lafurl"
            :laffilepath="laffilepath"
          ></wang-editor>
        </el-form-item>

        <el-form-item label="编辑">
          <el-button @click="removeItem(index)">删除</el-button>
        </el-form-item>

        <el-divider></el-divider>
      </div> -->

      <el-form-item>
        <el-button @click="addItem">添加表单</el-button>

        <el-button type="primary" @click="onSubmit">提交表单</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import WangEditor from "@/components/WangEditor/WangEditor.vue";
import { postdata } from "@/api";
import { title, subtitle } from "./name";

import { mapState } from "vuex";
export default {
  components: {
    //quillEditor,
    WangEditor,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      laffilepath: "mianyi",
      lafurl: "/getmianyi",
      options: [],
      formData: {
        _id: "",
        name: "",
        flag: "data",
        time: "",
        items: [
          {
            // title: {
            //   value: "",
            //   options: [],
            // },
            subtitlevalue: "",
            editorvalue: "",
            subtitle: {
              value: "",
              options: [],
            },
            editor: {
              value: "",
            },
          },
        ],
      },
    };
  },

  mounted() {
    if (this.title == "编辑") {
      console.log("传过来的行数据", this.list);

      this.$nextTick(() => {
        if (this.list) {
          this.formData.name = this.list.name || "";
          this.formData._id = this.list._id || "";
          this.formData.time = this.list.time || "";
          this.formData.items = this.list.items.map(item => ({
            subtitlevalue: item.subtitlevalue,
            editorvalue: item.editorvalue,
            subtitle: {
              value: item.subtitle?.value || "",
              options: subtitle
            },
            editor: {
              value: item.editorvalue || "<p>请输入</p>"
            }
          }));

          console.log("###################", this.formData.items);
        } else {
          console.warn("this.list 数据未定义或未正确传入");
        }
      });
    
    }
  },
  computed: {
    ...mapState("mianyi", ["title", "list"]),
  },

  created() {
    //console.log("js文件数据", title, subtitle);
    this.options = title;
    // this.formData.items[0].title.options = title;
    this.formData.items[0].subtitle.options = subtitle;
  },
  methods: {
    sendEditor(val, index) {
      console.log("从WangEditor传来的数据", val, index);
      this.formData.items[index].editorvalue = val;
      this.formData.items[index].editor.value = val;
      //this.ruleForm.desc = val
    },
    addItem() {
      this.formData.items.push({
        // title: {
        //   value: "",
        //   options: title,
        // },
        subtitle: {
          value: "",
          options: subtitle,
        },
        editor: {
          value: "<p>请输入内容</p >",
        },
      });
    },
    removeItem(index) {
      console.log("删除", index);

      if (this.formData.items.length == 1) {
        return false;
      }

      this.formData.items.splice(index, 1);
    },

    async dealdata(url, data) {
      return await postdata(url, data).then((res) => {
        console.log("免疫", res.data);
        return res;
      });
    },
    onSubmit() {
      console.log("提交的数据:", this.formData);
      const data = { ...this.formData };
      this.removeOptions(data); // 调用函数
      console.log(data);

      this.dealdata(this.lafurl, data);
      this.$router.push("/mianyi");
    },

    removeOptions(obj) {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (key === "option" && Array.isArray(obj[key])) {
            delete obj[key]; // 删除名为 'options' 的属性，如果它是数组
          } else if (key === "subtitle" || key === "editor") {
            delete obj[key];
          } else if (typeof obj[key] === "object" && obj[key] !== null) {
            this.removeOptions(obj[key]); // 递归调用处理嵌套对象
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.formbox {
  background-color: white;
  padding: 16px;
  height: 100%;
}
</style>

<!-- <el-button type="submit">提交表单</el-button> -->
<!-- <el-button>取消</el-button> -->
<!-- <form @submit.prevent="submitForm" class="formbox">
      <div v-for="(item, index) in formData.items" :key="index" class="item">

         <select v-model="item.select.value">
          <option disabled value="">请选择</option>
          <option
            v-for="option in item.select.options"
            :key="option.value"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </select> 
         <quill-editor
          v-model="item.editor.value"
          :options="editorOptions"
        ></quill-editor> 

        <el-select v-model="item.select.value" placeholder="请选择">
          <el-option
            v-for="item in item.select.options"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <wang-editor></wang-editor>

        <el-button @click="removeItem(index)">删除</el-button> 
      </div>
 <el-button type="primary" @click="addItem">添加表单项</el-button>
      <el-button type="submit">提交表单</el-button> 
    </form> -->
