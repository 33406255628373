<template>
  <div id="app">
    <!-- 路由出口 -->
    <!-- 路由匹配到的组件将渲染在这里 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    // if (this._isMobile()) {
    //   // 跳转至手机端路由
    //   alert("手机端");
    //   this.$router.replace("/phonelogin");
    // } else {
    //   // 跳转至 pc 端路由
    //   //alert("pc端");
    //   //this.$router.push("/pclogin");
    //   //this.$router.replace({ path: "/" }).catch(() => {}); // .catch捕获错误
    //   //this.$router.replace({ path: '/pclogin' }).catch(() => {}) // .catch捕获错误
    // }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

h3 {
  margin: 0;
  padding: 0;
}
</style>
