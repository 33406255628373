<template>
  <div class="treebox">
    <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick" />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { postdata } from "@/api";

const emit = defineEmits(["node-click"]);

const data = ref([]);

const getdata = async () => {
  const res = await postdata("/getjijiu", { flag: "fl" }).then((data) => {
    let result = data.data;
    //console.log("获取急救数据", result);
    return result.jijiuarr;
  });
  console.log("获取急救数据", res);
  data.value = res;
};

onMounted(() => {
  console.log("组件已挂载");
  getdata();
});

const handleNodeClick = (nodeData) => {
  console.log(
    "handleNodeClick------------子组件点击触发事件并传递数据",
    nodeData,
    // node,
    // component
  );
  emit("node-click", nodeData);

  //   const obj = { name: data.text }; // 假设这是你想传递的对象
  //   store.dispatch('setFormData', obj);
};

const defaultProps = {
  children: "children",
  label: "label",
};
</script>


<style lang="less" scoped>
  .treebox{
    background-color: white;
    padding-top: 6px;
  }
</style>