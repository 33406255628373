import axios from "axios";

const http = axios.create({
    baseURL: 'https://nnjacn.laf.run',
    timeout: 10000,
    headers: {
       Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEyMywiZXhwIjoxNzU5NTUyNDIyLCJpYXQiOjE3MjgwMTY0MjJ9.KtWEb5Pgi7bwEbh0spD_UG7SuCctg05C3CYUf-FVgUs`
    }
});


// 添加请求拦截器
http.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
http.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 对响应错误做点什么
    console.log(error.message);
    
    return Promise.reject(error);
});

export default http