<!-- eslint-disable no-undef -->
<template>
  <div class="box">
    <div
      ref="vantaRef"
      style="width: 100%; height: 100vh; "
    ></div>
    <div class="banner">
      <p>账号<el-input type="text" /></p>
      <p>密码<el-input type="text" /></p>
      <el-button>登录</el-button>
    </div>
  </div>
</template>
<script>
import * as THREE from "three";
import NET from "vanta/src/vanta.net";

export default {
  data() {
    return {};
  },
  mounted() {
    this.vantaEffect = NET({
      el: this.$refs.vantaRef,
      THREE: THREE,
    });
    // eslint-disable-next-line no-undef
    VANTA.NET({
       el: this.$refs.vantaRef,
      /*以下为样式配置*/
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0xc7d1e8,
      backgroundColor: 0x400bb1,
      points: 13.0,
      maxDistance: 21.0,
      spacing: 16.0,
    });
  },
  beforeDestroy() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy();
    }
  },
};
</script>

<style lang="less" scoped>
.box {
  position: relative;
  .banner {
    width: 400px;
    height: 300px;
    z-index: 999;
    position: absolute;
    top: 26%;
    right: 10%;
    // color: #fff;
    background-color: #fff;
    border-radius: 2%;
  }
}
p {
  margin-top: 20px;
}
.el-button {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
</style>
