import Vue from 'vue'
import Vuex from 'vuex'
import tab from './tab'
import jijiu from './jijiu'
import mianyi from './mianyi'
import createPersistedstate from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        tab,
        jijiu,
        mianyi
    },
    state: {
        formData: {
            name: ''
        }
    },
    mutations: {
        updateFormData(state, payload) {
            state.formData = {
                ...state.formData,
                ...payload
            };
        }
    },
    actions: {
        setFormData({
            commit
        }, payload) {

            console.log('payload',
                payload);

            commit('updateFormData', payload);
        }
    },

    plugins: [
        createPersistedstate({
            key: 'info',
            paths: ['jijiu','tab','mianyi']
        })
    ]
})