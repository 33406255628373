<template>
  <div class="formbox">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
    >
      <el-form-item label="药品名称" prop="name">
        <el-input
          v-model="ruleForm.name"
          @input="updateForm('name', $event)"
        ></el-input>
      </el-form-item>
      <el-form-item label="药品分类" prop="region">
        <el-select v-model="ruleForm.region" placeholder="请选择">
          <el-option
            :label="item.text"
            :value="item.text"
            v-for="item in options"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- <el-form-item label="上传图片" prop="picture">
      <el-upload
        class="upload-demo"
        ref="upload"
        action="https://nnjacn.laf.run/uploadfile"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :file-list="fileList"
        :auto-upload="false"
        :on-success="handleSuccess"
    :on-error="handleError"
      >
        <el-button slot="trigger" size="small" type="primary"
          >选取文件</el-button
        >
        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="submitUpload"
          >上传到服务器</el-button
        >
        <div slot="tip" class="el-upload__tip">
          只能上传jpg/png文件，且不超过500kb
        </div>
      </el-upload>
    </el-form-item> -->

      <!-- <el-form-item label="活动时间" required>
      <el-col :span="11">
        <el-form-item prop="date1">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="ruleForm.date1"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col class="line" :span="2">-</el-col>
      <el-col :span="11">
        <el-form-item prop="date2">
          <el-time-picker
            placeholder="选择时间"
            :picker-options="{
              start: '08:30',
              step: '00:15',
              end: '18:30',
            }"
            v-model="ruleForm.date2"
            style="width: 100%"
          ></el-time-picker>
        </el-form-item>
      </el-col>
    </el-form-item> -->
      <el-form-item label="药品描述" prop="desc">
        <WangEditor></WangEditor>
      </el-form-item>
      <!-- <el-form-item label="活动性质" prop="type">
      <el-checkbox-group v-model="ruleForm.type">
        <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
        <el-checkbox label="地推活动" name="type"></el-checkbox>
        <el-checkbox label="线下主题活动" name="type"></el-checkbox>
        <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
      </el-checkbox-group>
    </el-form-item> -->
      <el-form-item label="药品级别" prop="resource">
        <el-radio-group v-model="ruleForm.resource">
          <el-radio
            :label="item.text"
            :value="item.text"
            v-for="item in kssflag"
            :key="item.value"
          ></el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item label="活动形式" prop="delivery">
      <el-input type="textarea" v-model="ruleForm.delivery"></el-input>
    </el-form-item> -->

      <el-form-item label="附加文件" required>
        <el-upload
          v-model="ruleForm.file"
          ref="upload"
          action=""
          :http-request="uploadFileDrawer"
          :show-file-list="false"
          :file-list="drawerFileList"
          :auto-upload="false"
          :limit="1"
          :on-change="handleChange"
          :on-exceed="exceedFile"
          :headers="uploadHeaders"
        >
          <el-link
            :underline="false"
            slot="trigger"
            icon="el-icon-link"
            type="info"
            >添加附件</el-link
          >
        </el-upload>
        <div class="file-list">
          <span v-for="(item, index) in drawerFileList" :key="item.url">
            <el-link
              class="file-link"
              :underline="false"
              icon="el-icon-link"
              type="primary"
              :href="item.url"
              target="_blank"
              >{{ item.name }}</el-link
            >
            <i
              @click="delete_drawerFileList(index)"
              class="el-icon-delete el-icon--right"
            ></i>
          </span>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >立即创建</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { postdata } from "@/api";
import WangEditor from "@/components/WangEditor/WangEditor.vue";
import { onMounted } from "vue";
import { mapState } from "vuex";
// import dayjs from "dayjs";
export default {
  components: { WangEditor },
  data() {
    return {
      drawerFileList: [],
      ruleForm: {
        name: "",
        region: "",
        // date1: "",
        // date2: "",
        delivery: false,
        //type: [],
        resource: "",
        //desc: "",
        file: "",
        flag: "data",
        fileurl: "",
      },
      uploadHeaders: {
        // "Authorization":
        //   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjEyMywiZXhwIjoxNzU5NTUyNDIyLCJpYXQiOjE3MjgwMTY0MjJ9.KtWEb5Pgi7bwEbh0spD_UG7SuCctg05C3CYUf-FVgUs",
      },
      options: [],
      kssflag: [],
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        region: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        picture: [{ required: true, message: "请选择图片", trigger: "change" }],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择活动资源", trigger: "change" },
        ],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
    };
  },

  computed: {
    ...mapState({
      ruleForm: (state) => state.formData,
    }),
  },

  methods: {
    updateForm(field, value) {
      this.$store.dispatch("setFormData", { [field]: value });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.$message.error("只能选择1个文件发反反复复反反复复", files, fileList);
    },
    //上传文件让第二次覆盖第一的文件
    handleChange(file, fileList) {
      console.log(file, fileList);

      this.ruleForm.file = file.raw;
      this.drawerFileList = fileList;
      // console.log("选择的文件", this.ruleForm.file);
      let formData = new FormData();
      formData.append("file", this.ruleForm.file);
      formData.append("flag", "file");
      // 遍历 FormData 对象中的键值对
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }

      let fileurl = this.dealdata(formData);
      fileurl.then((r) => {
        console.log(r.data.msg);
        this.ruleForm.fileurl = r.data.url;
      });
    },
    delete_drawerFileList(i) {
      this.drawerFileList.splice(i, 1);
    },
    uploadFileDrawer(params) {
      console.log(params, "ggggggggg");
      this.ruleForm.append("file", params.file);
    },

    async dealdata(data) {
      return await postdata("/uploadfile", data).then((res) => {
        console.log("ddddddd", res);
        return res;
      });
    },

    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.drawerFileList.length == 0) {
            this.$message.error("请上传附件");
            return;
          }
          // 创建 FormData 对象并添加数据
          // let formData = new FormData();
          // formData.append("file", this.ruleForm.file);
          // formData.append("flag", "file");
          // // 遍历 FormData 对象中的键值对
          // for (let [key, value] of formData.entries()) {
          //   console.log(`${key}:`, value);
          // }

          // let fileurl = this.dealdata(formData);
          // this.ruleForm.date1 = dayjs(this.ruleForm.date1).format("YYYY-MM-DD");
          // this.ruleForm.date2=dayjs(this.ruleForm.date2).format("HH:mm:ss")
          // this.ruleForm.fileurl = fileurl;
          that.dealdata(this.ruleForm);
        } else {
          console.log("必填项未填完");
          return false;
        }
      });
    },

    async getdata() {
      const res = await postdata("/getkangshengsu", { flag: "kssfl" }).then(
        (data) => {
          let result = data.data;
          console.log("getkangshengsu", result);
          return result;
        }
      );
      console.log("formdata", res);
      this.options = res.classarr;
      this.kssflag = res.kssflag;
    },
  },
  mounted() {
    this.getdata();

    console.log("this.$store.state.sharedData", this.$store.state.sharedData);
  },
};
</script>

<style lang="less" scoped>
.formbox {
  background-color: white;
  padding-top: 6px;
  height:100%
}
</style>
