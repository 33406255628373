import http from "../utils/request";

//请求数据接口
export const getdata = (url) => {
    return http.get(url)
}

export const postdata = (url,data) => {
    return http.post(url,data)
}



