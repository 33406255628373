<template>
  <div class="treebox">
    <el-row :gutter="10">
      <el-col :span="4">
        <div class="menu">
          <div class="title">分类列表</div>
          <Tree @change="treechange"></Tree>
        </div>
      </el-col>
      <el-col :span="20">
        <div class="content">
          <div class="subtitle">添加药品</div>
          <div class="wraper">
            <Form></Form>
          </div>
          <!-- <Form></Form> -->
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import Tree from "@/components/Tree/Tree.vue";
import Form from "@/components/Form/Form.vue";

const treechange = (tree) => {
  console.log("tree", tree.text);
};
</script>

<style lang="less" scoped>
.treebox {
  border-radius: 4px;
}
.menu {
  height: 600px;

  .title {
    height: 40px;
    line-height: 40px;
    color: white;
    background-color: skyblue;
    font-size: 16px;
    padding-left: 10px;
  }
}

.content {
  height: 800px;
  .subtitle {
    height: 40px;
    line-height: 40px;
    color: white;
    background-color: skyblue;
    font-size: 16px;
    padding-left: 10px;
  }
}
</style>
