<template>
  <div class="tabs">
    <el-tag
      v-for="(tag, index) in tags"
      :key="tag.name"
      :closable="tag.name !== 'home'"
      @click="changeMenu(tag)"
      @close="handleClose(tag, index)"
      :effect="$route.name === tag.name ? 'dark' : 'plain'"
      :type="tag.type"
    >
      {{ tag.label }}
    </el-tag>
  </div>

  <!-- :closable="tag.name !== 'clinic'" -->
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Tags",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      tags: (state) => state.tab.TabList,
    }),
  },
  methods: {
    ...mapMutations(["closeTag"]),
    changeMenu(item) {
      console.log(item);

      // 阻止重复导航到相同的路由
      if (this.$router.currentRoute.path !== item.name) {
        this.$router.push(item.name).catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            // 处理除了重复导航以外的错误
            console.error(err);
          }
        });
      }

      //this.$router.push({ name: item.name });
    },
    handleClose(item, index) {
      this.closeTag(item);
      const length = this.tags.length;
      //删除之后的跳转
      if (item.name !== this.$route.name) {
        return;
      }
      if (index === length) {
        this.$router.push({
          name: this.tags[index - 1].name,
        });
      } else {
        this.$router.push({
          name: this.tags[index].name,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tabs {
  margin-bottom: 5px;

  .el-tag {
    margin-right: 5px;
  }
}
</style>
