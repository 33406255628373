<template>
  <div>
    <div>
      <el-input
        placeholder="请输入内容"
        class="input-with-select"
      >
        <el-select slot="prepend" placeholder="请选择" v-model="selectedValue">
          <el-option label="模版类别" value="1"></el-option>
          <el-option label="问题列表" value="2"></el-option>
        </el-select>
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>
    <el-table :data="wenxinlist" style="width: 100%">
      <el-table-column prop="templateName" label="模版类别" width="180">
      </el-table-column>
      <el-table-column prop="templateContent" label="问题列表">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getdata } from "../api";
export default {
  data() {
    return {
      wenxinlist: [],
      selectedValue: '1' // 初始化 selectedValue
    };
  },

  mounted() {
    getdata("/getwenxin").then((data) => {
      //console.log("getwenxin", data.data);
      this.wenxinlist = data.data;
    });
  },
};
</script>

<style>
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>