<template>
  <div>
    <el-button type="primary" @click="addnew">+新增</el-button>

    <div style="margin-top: 8px">
      <el-table
        :data="userslist"
        style="width: 100%"
        border
        stripe
        :row-class-name="tableRowClassName"
        
      >
        <el-table-column prop="name" label="用户名"></el-table-column>

        <el-table-column prop="age" label="年龄"> </el-table-column>
        <el-table-column prop="sex" label="性别"></el-table-column>

        <el-table-column prop="birth" label="出生日期"> </el-table-column>
        <el-table-column prop="addr" label="地址"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <span slot="title">
        <span class="header-main-title">{{
          isnew ? "新增数据" : "编辑数据"
        }}</span>
      </span>
      <el-form
        :inline="true"
        :rules="rules"
        ref="form"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="姓名" prop="name">
          <el-input placeholder="请输入姓名" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="年龄" prop="age">
          <el-input placeholder="请输入年龄" v-model="form.age"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="form.sex" placeholder="请选择性别">
            <el-option label="男" value="男"></el-option>
            <el-option label="女" value="女"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="出生日期" prop="birth">
          <el-date-picker
            v-model="form.birth"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="地址" prop="addr">
          <el-input placeholder="请输入地址" v-model="form.addr"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getdata, postdata } from "../api";
import { useRouter } from "vue-router";
export default {
  data() {
    return {
      userslist: [],
      isnew: true,
      dialogVisible: false,
      form: {
        name: "",
        age: "",
        sex: "",
        birth: "",
        addr: "",
      },

      rules: {
        name: [{ required: true, message: "请输入姓名" }],
        age: [{ required: true, message: "请输入年龄" }],
        sex: [{ required: true, message: "请输入性别" }],
        birth: [{ required: true, message: "请输入出生日期" }],
        addr: [{ required: true, message: "请输入地址" }],
      },
    };
  },

  mounted() {
    this.getuserinfo();
  },

  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },
    handleClose() {
      console.log(this.$refs.form);
      //this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        console.log(valid, "valid");
        if (valid) {
          if (this.isnew) {
            this.manageuserinfo({ ...this.form, flag: "new" });
            //this.$refs.form.resetFields();
          } else {
            this.manageuserinfo({ ...this.form, flag: "edit" });
            //this.$refs.form.resetFields();
          }
          //this.$refs.form.resetFields();
          this.dialogVisible = false;
        }
      });
    },
    cancel() {
      this.handleClose();
    },

    async manageuserinfo(data) {
      console.log(data);
      await postdata("/useredit", data).then(async (data) => {
        console.log("useredit", data.data.data);
        await this.getuserinfo(); // 调用获取用户信息的函数，更新列表
      });
    },

    async getuserinfo() {
      await getdata("/getusers").then((data) => {
        console.log("getusers", data.data);
        this.userslist = data.data;
      });
    },

    // rowclick(row, event, column) {
    //   console.log('点击行的信息',row);
    //   this.dialogVisible = true;
    // },
    addnew() {
      //this.$refs.form.resetFields();
      this.isnew = true;
      this.form = {};
      this.dialogVisible = true;
      //this.$router.push('/drugmanage')
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.isnew = false;
      this.dialogVisible = true;
      this.form = row;
    },
    handleDelete(index, row) {
      console.log(index, row);

      this.manageuserinfo({ ...row, flag: "delete" });
      this.getuserinfo();
    },
  },
};
</script>
<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<!-- <template>
  <div>
    <div>
      <el-input
        placeholder="请输入内容"
        class="input-with-select"
      >
        <el-select slot="prepend" placeholder="请选择">
          <el-option label="管理员用户" value="1"></el-option>
          <el-option label="普通用户" value="1"></el-option>
        </el-select>
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>
    <el-table :data="userslist" style="width: 100%">
      <el-table-column prop="username" label="用户名"></el-table-column>

      <el-table-column prop="password" label="密码"> </el-table-column>
    </el-table>
  </div> 
</template>

<script>
import { getdata } from "../api";
export default {
  data() {
    return {
      userslist: [],
    };
  },

  mounted() {
    getdata("/getusers").then((data) => {
      console.log("getusers", data.data);
      this.userslist = data.data;
    });
  },
};
</script>

<style>
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style> -->
