export default {
    state: {
        isCollapse: false,
        TabList: [{
                path: "/",
                name: "home",
                label: "首页",
                icon: "s-home",
                url: "Home/Home",
            }

        ]
    },
    mutations: {
        collapseMenu(state) {
            state.isCollapse = !state.isCollapse
        },
        //更新面包屑
        selectMenu(state, val) {
            console.log(val, 'val');
            if (val.name !== "home") {
                const index = state.TabList.findIndex(item => item.name === val.name)
                if (index === -1) {
                    state.TabList.push(val)
                }
            }
        },
        closeTag(state, item) {
            console.log(item, 'item');
            const index = state.TabList.findIndex(val => val.name === item.name)
            state.TabList.splice(index, 1)
        }
    }
}