import Vue from 'vue'
import VueRouter from 'vue-router'
import Clinic from '../views/Clinic.vue';
import User from '../views/User.vue';
import Main from '../views/Main.vue';
import Mall from '../views/Mall.vue';
import Export from '../views/Export.vue';
import DrugManage from '../views/DrugManage.vue';
import Wenxin from '../views/Wenxin.vue';
import JijiuManage from '@/views/JijiuManage/JijiuManage.vue'
import Jijiu from '@/views/Jijiu/Jijiu.vue'
import Mianyi from '@/views/Mianyi/Mianyi.vue';
import MianyiManage from '@/views/MianyiManage/MianyiManage.vue';
import Home from '@/views/Home/Home.vue'
import PhoneLogin from '@/views/Login/PhoneLogin.vue'
import PcLogin from '@/views/Login/PcLogin.vue'
Vue.use(VueRouter)
const routes = [{
    path: '/',
    component: Main,
    redirect: '/home',
    children: [{
        path: 'home',
        name: 'home',
        component: Home
      },
      {
        path: 'clinic',
        name: 'clinic',
        component: Clinic
      },
      {
        path: 'jijiumanage',
        name: 'jijiumanage',
        component: JijiuManage
      },
      {
        path: 'jijiu',
        name: 'jijiu',
        component: Jijiu
      },
      {
        path: 'mianyi',
        name: 'mianyi',
        component: Mianyi,
        children: [{
          path: 'mianyimanage',
          name: 'mianyimanage',
          component: MianyiManage
        }]
      },
      {
        path: 'mianyimanage',
        name: 'mianyimanage',
        component: MianyiManage
      },
      {
        path: 'user',
        name: 'user',
        component: User
      }, {
        path: 'mall',
        name: 'mall',
        component: Mall
      }, {
        path: 'export',
        name: 'export',
        component: Export
      }, {
        path: 'drugmanage',
        name: 'drugmanage',
        component: DrugManage
      },
      {
        path: 'wenxin',
        name: 'wenxin',
        component: Wenxin
      },
      // {
      //   path: '/login',
      //   component: Login,
      // }
    ]
  },
  // pc端的路由
  {
    path: '/pclogin',
    name: 'pclogin',
    component: PcLogin
  },
  // 手机端的路由
  {
    path: '/phonelogin',
    name: 'phonelogin',
    component: PhoneLogin
  }

]
// const routes = [{
//     path: '/',
//     component: Main,
//     redirect: '/clinic',
//     children: [{
//         path: 'clinic',
//         name: 'clinic',
//         component: Clinic
//       },
//       {
//         path: 'jijiumanage',
//         name: 'jijiumanage',
//         component: JijiuManage
//       },
//       {
//         path: 'jijiu',
//         name: 'jijiu',
//         component: Jijiu
//       },
//       {
//         path: 'user',
//         name:'user',
//         component: User
//       }, {
//         path: 'mall',
//         name:'mall',
//         component: Mall
//       }, {
//         path: 'export',
//         name: 'export',
//         component: Export
//       }, {
//         path: 'drugmanage',
//         name: 'drugmanage',
//         component: DrugManage
//       },
//       {
//         path: 'wenxin',
//         name:'wenxin',
//         component: Wenxin
//       }
//     ]
//   }

// ]
const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})

router.onError(error => {
  if (error.name === 'NavigationDuplicated') {
    // 忽略重复导航错误
    return;
  }
  console.error(error);
});

router.beforeEach((to, from, next) => {
  if (to.path === from.path) {
    // 如果即将进入的路由与当前路由相同，则不进行导航
    next(false);
  } else {
    next(); // 正常执行其他路由
  }
});


export default router