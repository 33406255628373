<template>
  <div class="treebox">
    <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick" />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { postdata } from "@/api";
import { getCurrentInstance } from "vue";

const vm = getCurrentInstance().proxy;
const store = vm.$store;

const emit = defineEmits(["change"]);

const data = ref([]);

const getdata = async () => {
  const res = await postdata("/getkangshengsu", { flag: "kssfl" }).then(
    (data) => {
      let result = data.data;
      console.log("getkangshengsu", result);
      return result.re;
    }
  );
  console.log("data", res);
  data.value = res;
};

onMounted(() => {
  console.log("组件已挂载");
  getdata();
});

const handleNodeClick = (data) => {
  console.log(data.text);
  emit("change", data);

  const obj = { name: data.text }; // 假设这是你想传递的对象
  store.dispatch('setFormData', obj);
};

const defaultProps = {
  children: "children",
  label: "text",
};
</script>
<style lang="less" scoped>
  .treebox{
    background-color: white;
    padding-top: 6px;
  }
</style>